import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { values } from 'lodash';
import { insiderProductIdClear, setInsiderProductObject, setInsiderPageType } from 'utils/insiderUtils';
import { brandIdToShortNameConverter } from 'utils/brandIdToNameConverter';
import { analyticsSelectItem, analyticsViewItem } from 'utils/analyticsUtils';
import ProductHeaderPanel from './ProductHeaderPanel';
import OptionPanel from './OptionPanel';
import AddToCartSegment from './AddToCartSegment';
import Faq from './Faq';
import './productDetail.scss';

const ProductDetailScreen = ({ product }) => {
  const [isLargeMenu, setIsLargeMenu] = useState(false);
  const [addToCartDataState, setAddToCartDataState] = useState({
    price: product?.product?.price,
    options: {},
  });

  useEffect(() => {
    window?.dataLayer?.filter((el) => !el.cd_pageType);
    window?.dataLayer?.push({
      cd_pageType: 'detay sayfası',
    });

    const productOptions = [];
    product?.product?.descriptionList?.map((description) => {
      productOptions.push(description?.Description);
      return null;
    });

    const brandID = product?.product?.brand?.val;
    const productName = product?.product?.name;

    const shortBrandName = brandIdToShortNameConverter(brandID);
    const productObject = {
      id: insiderProductIdClear(product?.product?.value),
      name: product?.product?.name,
      taxonomy: shortBrandName ? [shortBrandName] : [''],
      currency: 'TRY',
      unit_price: product?.product?.price,
      unit_sale_price: product?.product?.price,
      url: window.location.href,
      stock: 1000,
      product_image_url: product?.product?.productImage?.Media?.Url || '',
      custom: {
        brand_name: shortBrandName || '',
        is_campaign: false,
        campaign_name: '',
        is_special: false,
        special_name: '',
        bundle_quantity: 0,
        gamer_offer: false,
        football_offer: false,
        has_option: product?.product?.hasOptions || false,
        productOptions: JSON.stringify(productOptions),
        usage_type: 'Sana Gelsin',
      },
    };
    setInsiderProductObject(productObject);
    setInsiderPageType('Product', [shortBrandName, productName]);

    const listName = window?.localStorage?.getItem('item_list_name');
    analyticsSelectItem(
      {
        name: product?.product?.name,
        id: product?.product?.value,
        brand: shortBrandName,
        price: product?.product?.price,
      },
      {
        id: '00000000-0000-0000-0000-000000000000',
        name: listName,
      },
    );
    analyticsViewItem({
      name: product?.product?.name,
      id: product?.product?.value,
      brand: shortBrandName,
      price: product?.product?.price,
    });
  }, [product]);

  const updateAddToCartData = (parentOption, parentOptionItem, option, item, isChildOption, quantity, extraPrice) => {
    const addToCartData = { ...addToCartDataState };

    const addSelectionToAddToCartData = (optionToAdd, itemToAdd, quantityToAdd, extraPriceToAdd) => {
      const { productOptionTypeId: key, controlType } = optionToAdd;
      const { firstChoice, standardQuantity } = itemToAdd;
      const noOption = addToCartData?.options[key] === undefined;
      if (noOption)
        addToCartData.options[key] = {
          optionTitle: optionToAdd.title,
          selections: {},
        };

      if (controlType === 1) {
        if (firstChoice && !item?.pricePerProductOption) {
          delete addToCartData?.options[key];
        } else {
          addToCartData.options[key].selections = {};
          addToCartData.options[key].selections[itemToAdd.id] = {
            title: itemToAdd.title,
            id: itemToAdd.id,
            productId: itemToAdd.productId,
            quantity: quantityToAdd,
            extraPrice: extraPriceToAdd,
            options: {},
            addAsOption: true,
            UniqueId: itemToAdd?.UniqueId || key,
          };
        }
      }

      if (controlType === 2) {
        const shouldDelete = quantityToAdd === standardQuantity;

        if (shouldDelete) {
          delete addToCartData.options[key].selections[itemToAdd.id];
        } else {
          addToCartData.options[key].selections[itemToAdd.id] = {
            title: itemToAdd.title,
            id: itemToAdd.id,
            productId: itemToAdd.productId,
            quantity: quantityToAdd,
            extraPrice: extraPriceToAdd,
            options: {},
            addAsOption: true,
            UniqueId: itemToAdd?.UniqueId || key,
          };
        }
      }

      if (controlType === 3 || controlType === 4) {
        const shouldDelete = (firstChoice && quantityToAdd === 1) || (!firstChoice && quantityToAdd === 0);
        if (shouldDelete) {
          delete addToCartData.options[key].selections[itemToAdd.id];
        } else {
          addToCartData.options[key].selections[itemToAdd.id] = {
            title: itemToAdd.title,
            id: itemToAdd.id,
            productId: itemToAdd.productId,
            quantity: quantityToAdd,
            extraPrice: extraPriceToAdd,
            options: {},
            addAsOption: true,
            UniqueId: itemToAdd?.UniqueId || key,
          };
        }
      }
    };

    const addChildSelectionToAddToCartData = (
      parentOptionToAdd,
      parentOptionItemToAdd,
      optionToAdd,
      itemToAdd,
      quantityToAdd,
      extraPriceToAdd,
    ) => {
      const { productOptionTypeId: parentKey } = parentOptionToAdd;
      const { id: parentItemKey } = parentOptionItemToAdd;
      const { productOptionTypeId: key, controlType } = optionToAdd;
      const { firstChoice, standardQuantity } = itemToAdd;

      const noOption = addToCartData.options[parentKey] === undefined;
      if (noOption)
        addToCartData.options[parentKey] = {
          optionTitle: parentOptionToAdd.title,
          selections: {},
        };

      const noSelection = addToCartData.options[parentKey].selections[parentItemKey] === undefined;
      if (noSelection) {
        addToCartData.options[parentKey].selections[parentItemKey] = {
          title: parentOptionItemToAdd.title,
          id: parentOptionItemToAdd.id,
          productId: parentOptionItemToAdd.productId,
          options: {},
        };
      }
      const noChildOption = addToCartData.options[parentKey].selections[parentItemKey].options[key] === undefined;
      if (noChildOption)
        addToCartData.options[parentKey].selections[parentItemKey].options[key] = {
          optionTitle: optionToAdd.title,
          selections: {},
        };

      if (controlType === 1) {
        if (firstChoice) {
          delete addToCartData.options[parentKey].selections[parentItemKey].options[key];
        } else {
          addToCartData.options[parentKey].selections[parentItemKey].options[key].selections = {};
          addToCartData.options[parentKey].selections[parentItemKey].options[key].selections[itemToAdd.id] = {
            title: itemToAdd.title,
            id: itemToAdd.id,
            quantity: quantityToAdd,
            extraPrice: extraPriceToAdd,
            parentGroupName: itemToAdd.parentGroupName,
            UniqueId: parentOption?.UniqueId || key,
          };
        }
      }

      if (controlType === 2) {
        const shouldDelete = quantityToAdd === standardQuantity;

        if (shouldDelete) {
          delete addToCartData.options[parentKey].selections[parentItemKey].options[key].selections[itemToAdd.id];
        } else {
          addToCartData.options[parentKey].selections[parentItemKey].options[key].selections[itemToAdd.id] = {
            title: itemToAdd.title,
            id: itemToAdd.id,
            quantity: quantityToAdd,
            extraPrice: extraPriceToAdd,
            parentGroupName: itemToAdd.parentGroupName,
            UniqueId: parentOption?.UniqueId || key,
          };
        }
      }

      if (controlType === 3 || controlType === 4) {
        const shouldDelete = (firstChoice && quantityToAdd === 1) || (!firstChoice && quantityToAdd === 0);
        if (shouldDelete) {
          delete addToCartData.options[parentKey].selections[parentItemKey].options[key].selections[itemToAdd.id];
        } else {
          addToCartData.options[parentKey].selections[parentItemKey].options[key].selections[itemToAdd.id] = {
            title: itemToAdd.title,
            id: itemToAdd.id,
            quantity: quantityToAdd,
            extraPrice: extraPriceToAdd,
            parentGroupName: itemToAdd.parentGroupName,
            UniqueId: parentOption?.UniqueId || key,
          };
        }
      }

      if (
        values(addToCartData?.options?.[parentKey]?.selections?.[parentItemKey]?.options?.[key]?.selections)?.length ===
        0
      )
        delete addToCartData.options[parentKey].selections[parentItemKey].options[key];
      if (
        values(addToCartData?.options?.[parentKey]?.selections?.[parentItemKey]?.options)?.length === 0 &&
        parentOptionItemToAdd?.firstChoice
      )
        delete addToCartData.options[parentKey].selections[parentItemKey];
      if (values(addToCartData?.options?.[parentKey]?.selections)?.length === 0)
        delete addToCartData.options[parentKey];
    };

    if (isChildOption) {
      addChildSelectionToAddToCartData(parentOption, parentOptionItem, option, item, quantity, extraPrice);
    }

    if (!isChildOption) {
      addSelectionToAddToCartData(option, item, quantity, extraPrice);
    }

    let total = product?.product?.price || 0;
    values(addToCartData.options).forEach((o) => {
      values(o.selections).forEach((s) => {
        total += s.extraPrice || 0;
        values(s.options).forEach((co) => {
          values(co.selections).forEach((cs) => {
            total += cs.extraPrice || 0;
          });
        });
      });
    });

    addToCartData.price = total;
    setAddToCartDataState(addToCartData);

    if (typeof item.isLargeMenu === 'boolean') {
      setIsLargeMenu(item.isLargeMenu);
    }
  };
  return (
    <>
      <Row>
        <Col lg={5}>
          <div className="add-basket-wrapper">
            <ProductHeaderPanel product={product?.product} />
            <AddToCartSegment product={product?.product} addToCartData={addToCartDataState} />
          </div>
        </Col>
        <Col lg={7} className="panel">
          {product?.options?.map((o) => (
            <OptionPanel
              brandId={product.product.brand.val}
              key={o.productOptionTypeId}
              option={o}
              childOption={false}
              isLargeMenu={isLargeMenu}
              onChange={updateAddToCartData}
            />
          ))}
        </Col>
      </Row>
      {product?.product?.FAQ?.length > 0 && <Faq data={product.product.FAQ} />}
    </>
  );
};

ProductDetailScreen.propTypes = {
  product: PropTypes.any,
};

export default ProductDetailScreen;
