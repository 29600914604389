import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import './dropdown.scss';
import { turkishCharacterRegex } from 'utils/regex';

function Dropdown(props) {
  const { placeholder, options, onChange, value, label, disableFilter, error, customDropdownClassName } = props;

  const customFilter = (option, searchText) => {
    if (disableFilter) return option.data.label;
    return turkishCharacterRegex(option.data.label)
      .toLowerCase()
      .includes(turkishCharacterRegex(searchText).toLowerCase());
  };
  return (
    <div className={`${customDropdownClassName} dropdown-container`}>
      {label && <div className="form-label dropdown">{label}</div>}
      <Select
        className="dropdown-select"
        onChange={onChange}
        placeholder={placeholder}
        options={options}
        value={value}
        filterOption={customFilter}
        {...props}
      />
      {error && <div className="dropdown-error">{error}</div>}
    </div>
  );
}

Dropdown.propTypes = {
  placeholder: PropTypes.string,
  customDropdownClassName: PropTypes.object,
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  disableFilter: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default memo(Dropdown);
