import { convertToRfcFormat } from './dateUtils';
export const setInsiderPageType = (pageName) => {
  let product = {};
  let listing = {};
  if (pageName === 'Product') {
    product = window.insider_object?.product;
  }
  if (pageName === 'Listing' || pageName === 'Category') {
    listing = window.insider_object?.listing;
  }

  window.insider_object = {
    ...window.insider_object,
    product,
    listing,
    page: {
      type: pageName,
    },
  };
};

export const setInsiderUserObject = (userObject) => {
  window.insider_object = {
    ...window.insider_object,
    user: userObject,
  };
};

export const setInsiderProductObject = (productObject) => {
  window.insider_object = {
    ...window.insider_object,
    product: productObject,
  };
};

export const setInsiderListingObject = (itemsArray) => {
  window.insider_object = {
    ...window.insider_object,
    listing: {
      items: itemsArray,
    },
  };
};

export const setInsiderBasketObject = (basketObject) => {
  window.insider_object = {
    ...window.insider_object,
    basket: basketObject,
  };
};

export const setInsiderTransactionObject = (transactionObject) => {
  window.insider_object = {
    ...window.insider_object,
    transaction: transactionObject,
  };
};

export const eventInsider = (eventName, eventParams) => {
  window?.Insider?.track('events', [
    {
      event_name: eventName,
      timestamp: convertToRfcFormat(new Date()),
      event_params: eventParams,
    },
  ]);
};

export const insiderProductIdClear = (productId) => productId?.split('-')?.join('') || '';
