import dayjs from 'dayjs';

const updateLocale = require('dayjs/plugin/updateLocale');

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  months: [
    'Ocak',
    'Şubat',
    'Mart',
    'Nisan',
    'Mayıs',
    'Haziran',
    'Temmuz',
    'Ağustos',
    'Eylül',
    'Ekim',
    'Kasım',
    'Aralık',
  ],
});

export const DateFormats = {
  DAY_MONTH_YEAR: 'D MMMM YYYY',
  DATE_TIME: 'DD/MM/YYYY - HH:mm',
};

export function convertToRfcFormat(date) {
  function pad(n) {
    return n < 10 ? `0${n}` : n;
  }
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(
    date.getMinutes(),
  )}:${pad(date.getSeconds())}Z`;
}
