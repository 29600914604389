import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import OptionPanel from '../OptionPanel';
import './optionItemPanel.scss';

const OptionItemPanel = ({ option, hideIfNoChildOption, hideTitle, item, onChange }) => {
  if (item === null) return null;

  const { childOptions, pricePerProductOption, title } = item || {};

  const noChildOption = !childOptions || childOptions?.length === 0;

  if (noChildOption && hideIfNoChildOption) return null;

  return (
    <div className="option-item-panel">
      {!hideTitle && <div className="option-item-panel-title">{`${title} - ${pricePerProductOption}`}</div>}
      {childOptions &&
        childOptions.map((co) => (
          <OptionPanel
            parentOption={option}
            parentOptionItem={item}
            childOption
            key={co.productOptionTypeId}
            option={co}
            onChange={onChange}
          />
        ))}
    </div>
  );
};

OptionItemPanel.propTypes = {
  option: PropTypes.object,
  hideIfNoChildOption: PropTypes.bool,
  hideTitle: PropTypes.bool,
  item: PropTypes.object,
  onChange: PropTypes.func,
};

export default OptionItemPanel;
