import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { indexOf, sum } from 'ramda';
import Counter from '../../../components/UI/Counter';
import './optionPanel4.scss';

const OptionPanelControlType4 = ({ parentOption, parentOptionItem, option, childOption, onChange }) => {
  const { controlType, items = [], maximumOptioncount } = option || {};

  const [firstChoiceMapData, setFirstChoiceMapData] = useState([]);

  useEffect(() => {
    const firstChoiceMap = items.map((i) => ({
      name: i.title,
      itemId: i.id,
      quantity: i.standardQuantity,
    }));

    setFirstChoiceMapData(firstChoiceMap);
  }, [items]);

  if (controlType !== 4) return null;

  const sumQuantity = sum(firstChoiceMapData.map((i) => i.quantity));

  const limitReached = sumQuantity >= maximumOptioncount;

  return (
    <div className="option-panel-4">
      <div className="panel-title">
        {option.title} {JSON.stringify(sumQuantity)}/{maximumOptioncount}
      </div>
      {items.map((item) => (
        <OptionItemSelectable
          firstChoiceMapData={firstChoiceMapData}
          setFirstChoiceMapData={setFirstChoiceMapData}
          key={item.id}
          item={item}
          parentMaxQuantity={maximumOptioncount}
          limitReached={limitReached}
          parentOption={parentOption}
          parentOptionItem={parentOptionItem}
          childOption={childOption}
          option={option}
          onChange={onChange}
        />
      ))}
    </div>
  );
};

OptionPanelControlType4.propTypes = {
  parentOption: PropTypes.object,
  parentOptionItem: PropTypes.object,
  option: PropTypes.object,
  childOption: PropTypes.bool,
  onChange: PropTypes.func,
};

export default OptionPanelControlType4;

const OptionItemSelectable = ({
  parentOption,
  parentOptionItem,
  option,
  childOption,
  onChange,
  item,
  firstChoiceMapData,
  setFirstChoiceMapData,
  limitReached,
  parentMaxQuantity,
}) => {
  const { minQuantity, maxQuantity, title, pricePerProductOption, standardQuantity } = item;

  const [selectedQuantity, setSelectedQuantity] = useState(standardQuantity);

  const handleStepperValueChange = (quantityFromStepper) => {
    const data = firstChoiceMapData;

    const indexOfId = indexOf(
      item.id,
      data.map((i) => i.itemId),
    );

    data[indexOfId].quantity = quantityFromStepper;

    setFirstChoiceMapData(data);

    setSelectedQuantity(quantityFromStepper);

    const extraPrice2 = pricePerProductOption * Math.max(quantityFromStepper - standardQuantity, 0);
    onChange(parentOption, parentOptionItem, option, item, childOption, quantityFromStepper, extraPrice2);
  };

  return (
    <div className="option-panel-counter">
      <div className="counter-label">{title}</div>
      <Counter
        size="sm"
        disabledIncrement={limitReached}
        value={selectedQuantity}
        increment={() => handleStepperValueChange(selectedQuantity + 1)}
        decrement={() => handleStepperValueChange(selectedQuantity - 1)}
        minimumValue={minQuantity}
        maximumValue={Math.min(maxQuantity, parentMaxQuantity)}
      />
      {/* <Stepper
        disabledIncrement={limitReached}
        minimumValue={minQuantity}
        maximumValue={Math.min(maxQuantity, parentMaxQuantity)}
        outerValue={selectedQuantity}
        outerSetValue={handleStepperValueChange}
      /> */}
    </div>
  );
};

OptionItemSelectable.propTypes = {
  firstChoiceMapData: PropTypes.array,
  setFirstChoiceMapData: PropTypes.func,
  parentOption: PropTypes.object,
  parentOptionItem: PropTypes.object,
  parentMaxQuantity: PropTypes.number,
  option: PropTypes.object,
  item: PropTypes.object,
  childOption: PropTypes.bool,
  limitReached: PropTypes.bool,
  onChange: PropTypes.func,
};
