import React from 'react';
import PropTypes from 'prop-types';
import { Image, Row, Col } from 'react-bootstrap';
import './productHeaderPanel.scss';
import { findIsAmasyaEt } from 'utils/findSpesificBrands';
import CurrencyText from '../../../components/UI/CurrencyText';

export default function ProductHeaderPanel({ product }) {
  const media = product?.productImage?.Media?.Url;
  const descriptions = product?.descriptionList;
  const template = product?.productImage?.Template;
  const AmasyaEtBrandId = product?.brand?.val;
  const isAmasyaEt = AmasyaEtBrandId && findIsAmasyaEt(AmasyaEtBrandId);

  if (!media && !product?.media?.path) {
    return (
      <div className="product-header-panel no-image">
        <h1 className="product-name">
          {product?.brand.name} {'-'} {product?.name}
          <b> | TıklaGelsin</b>
        </h1>
        <div className="product-description">
          <span>
            {product?.brand.name} {'eşsiz lezzeti'} {product?.name}{' '}
            {"şimdi Tıklagelsin'de sende hemen sipariş ver yemeğin anında kapında olsun."}
          </span>
          <br />
          {product?.productContent ? product?.productContent : product?.description}
        </div>
      </div>
    );
  }
  return (
    <div className="product-header-panel">
      <div className="product-content-wrapper">
        <h1 className="product-name">
          {product?.brand.name} {'-'} {product?.name}
          <b> | Tıkla Gelsin</b>
        </h1>
      </div>
      <div className="product-desc text-left mb-1">
        <span>
          {product?.brand.name} {'eşsiz lezzeti'} {product?.name}{' '}
          {"şimdi Tıklagelsin'de sende hemen sipariş ver yemeğin anında kapında olsun."}
        </span>
      </div>

      {product?.description && isAmasyaEt === false && (
        <div className="product-desc text-left mb-3">{product?.description}</div>
      )}
      {template?.Left ||
        (template?.Bottom && (
          <div style={[{ position: 'absolute', marginLeft: 12 }, product?.productImage?.Template]}>
            {product?.previousPrice && <CurrencyText>{product?.previousPrice}</CurrencyText>}
            <CurrencyText>{product?.price}</CurrencyText>
          </div>
        ))}
      <div className="product-card-body">
        <Image src={media || product?.media?.path} alt={product?.name} className="mw-100 mh-100" />
        {descriptions && (
          <Row className="by-product-wrapper">
            {descriptions?.map((item) => (
              <Col xs={6} sm={6} md={6} lg={6}>
                <div className="by-product-item">
                  <i className={`icon-${item?.Icon?.Icon}`} />
                  <span>{item.Description}</span>
                </div>
              </Col>
            ))}
          </Row>
        )}
      </div>
      {isAmasyaEt && <AmasyaEtDescriptionSection product={product} />}
    </div>
  );
}

ProductHeaderPanel.propTypes = {
  product: PropTypes.any,
};

export function AmasyaEtDescriptionSection({ product }) {
  return (
    <div className="product-header-amasya-et">
      <div className="product-info">
        {product?.averagePackageGram && (
          <div>
            <span className="title">Ort. Ağırlık: </span>
            <span className="value">{`${product?.averagePackageGram} gr/paket`}</span>
          </div>
        )}
        {product?.kgPrice && (
          <div>
            <span className="title">Kilo Fiyatı: </span>
            <CurrencyText className="value">{product?.kgPrice}</CurrencyText>
          </div>
        )}
      </div>

      <div className="product-description">
        {product?.storageConditions && <span className="storage-conditions">{product?.storageConditions}</span>}
        <span className="description">{product?.description}</span>
      </div>
    </div>
  );
}

AmasyaEtDescriptionSection.propTypes = {
  product: PropTypes.any,
};
