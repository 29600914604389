/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import OptionPanelControlType1 from '../OptionPanelControlType1';
import OptionPanelControlType2 from '../OptionPanelControlType2';
import OptionPanelControlType3 from '../OptionPanelControlType3';
import OptionPanelControlType4 from '../OptionPanelControlType4';
import './optionPanel.scss';

function OptionPanel({ parentOption, parentOptionItem, option, childOption, isLargeMenu, onChange }) {
  if (option === null || !option) return null;

  if (option.showLargerSelection === true && !isLargeMenu) return null;

  return (
    <div className="option-panel">
      {!childOption && <h2 className="option-panel-title">{option.title}</h2>}
      <div className="option-panel-body">
        <OptionPanelControlType1
          parentOption={parentOption}
          parentOptionItem={parentOptionItem}
          option={option}
          childOption={childOption}
          isLargeMenu={isLargeMenu}
          onChange={onChange}
        />
        <OptionPanelControlType2
          parentOption={parentOption}
          parentOptionItem={parentOptionItem}
          option={option}
          childOption={childOption}
          isLargeMenu={isLargeMenu}
          onChange={onChange}
        />
        <OptionPanelControlType3
          parentOption={parentOption}
          parentOptionItem={parentOptionItem}
          childOption={childOption}
          option={option}
          isLargeMenu={isLargeMenu}
          onChange={onChange}
        />
        <OptionPanelControlType4
          parentOption={parentOption}
          parentOptionItem={parentOptionItem}
          option={option}
          childOption={childOption}
          isLargeMenu={isLargeMenu}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

OptionPanel.propTypes = {
  parentOptionItem: PropTypes.object,
  parentOption: PropTypes.object,
  option: PropTypes.object,
  childOption: PropTypes.bool,
  isLargeMenu: PropTypes.bool,
  onChange: PropTypes.func,
};

export default OptionPanel;
