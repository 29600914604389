/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import './Faq.scss';

export default function Faq(props) {
  const [activeRow, setActiveRow] = useState(-1);

  if (!props?.data?.length) {
    return null;
  }
  return (
    <Row>
      <Col>
        <div className="product-faq-container">
          <h1 className="product-faq-title">Sıkça Sorulan Sorular</h1>
          <div className="product-faq">
            {props.data?.map(({ Question, Answer }, index) => (
              <div key={Question} className={index === activeRow ? 'product-faq-item active' : 'product-faq-item'}>
                <div className="product-faq-item-title">
                  <button
                    type="button"
                    className="product-faq-item-title-button"
                    onClick={() => {
                      setActiveRow(index === activeRow ? -1 : index);
                    }}
                  >
                    <span>{Question}</span>
                    <i className="icon-Arrow_Simple_down" />
                  </button>
                </div>

                <div className="product-faq-item-content" dangerouslySetInnerHTML={{ __html: Answer }} />
              </div>
            ))}
          </div>
        </div>
      </Col>
    </Row>
  );
}
