import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { brandIdToShortNameConverter } from 'utils/brandIdToNameConverter';
import { analtyicsAddToCart } from 'utils/analyticsUtils';
import Counter from '../../../components/UI/Counter';
import Button from '../../../components/UI/Button';
import CurrencyText from '../../../components/UI/CurrencyText';
import './addToCartSegment.scss';

export function AddToCartSegment({ addToCartData, product }) {
  const [quantity, setQuantity] = useState(1);

  return (
    <div className="add-to-cart-segment">
      <Container>
        <CurrencyText>{addToCartData?.price * quantity}</CurrencyText>
        <div className="d-flex right-container">
          <Counter
            value={quantity}
            minimumValue={1}
            maximumValue={10}
            increment={() => setQuantity(quantity + 1)}
            decrement={() => setQuantity(quantity - 1)}
            roundButtons
            blackText
          />

          <Button
            onPress={() => {
              const shortBrandName = brandIdToShortNameConverter(product.brand.val);

              analtyicsAddToCart({
                id: product.value,
                name: product?.name,
                brand: shortBrandName,
                quantity,
                price: product?.price,
              });
              window.location.href = `/a/cproduct?brandId=${product.brand.val}&productId=${product.value}`;
            }}
          >
            Sepete Ekle
          </Button>
        </div>
      </Container>
    </div>
  );
}

AddToCartSegment.propTypes = {
  addToCartData: PropTypes.object,
  product: PropTypes.object,
};
export default AddToCartSegment;
