import React, { useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import OptionItemPanel from '../OptionItemPanel';
import Dropdown from '../../../components/UI/Dropdown';
import './optionPanel1.scss';

const OptionPanelControlType1 = ({ parentOption, parentOptionItem, option, childOption, isLargeMenu, onChange }) => {
  const { controlType, items = [] } = option || {};

  const firstChoiceArray = items.filter((i) => i.firstChoice);

  const defaultItem = firstChoiceArray.length === 0 ? items[0] : firstChoiceArray[0];

  const [selectedOptionItem, setSelectedOptionItem] = useState({
    ...defaultItem,
    label:
      (defaultItem?.isLargeMenu ? defaultItem.bigSizeName : defaultItem?.normalSizeName) +
      (defaultItem?.pricePerProductOption ? ` + ${defaultItem?.pricePerProductOption} TL` : ''),
  });
  const handlePickerValueChange = (optionItem) => {
    if (optionItem.id === selectedOptionItem.id) return;
    const { pricePerProductOption } = optionItem;
    setSelectedOptionItem(optionItem);
    // ASK : is quantity always 1 for control type 1
    // ASK : is standardQuantity  wrong
    onChange(parentOption, parentOptionItem, option, optionItem, childOption, 1, pricePerProductOption);
  };

  if (controlType !== 1) return null;

  const selectItems = items.map((item) => ({
    label:
      (isLargeMenu ? item.bigSizeName : item.normalSizeName) +
      (item.pricePerProductOption ? ` + ${item.pricePerProductOption} TL` : ''),
    key: item.id,
    value: item.id,
    ...item,
  }));

  return (
    <div>
      {childOption && <div>{option.title}</div>}
      <div>
        <Dropdown
          onChange={handlePickerValueChange}
          placeholder="Seçim yapınız."
          options={selectItems}
          value={selectedOptionItem}
        />
      </div>
      <OptionItemPanel hideTitle hideIfNoChildOption option={option} item={selectedOptionItem} onChange={onChange} />
    </div>
  );
};

OptionPanelControlType1.propTypes = {
  parentOption: PropTypes.object,
  parentOptionItem: PropTypes.object,
  option: PropTypes.object,
  isLargeMenu: PropTypes.bool,
  childOption: PropTypes.bool,
  onChange: PropTypes.func,
};

export default OptionPanelControlType1;
